.thank-you {
  padding-bottom: rem(25);

  @media(min-width: $screen-960) {
    padding-bottom: rem(70);
    margin-top: rem(-20px);
  }

  .column-wrapper {
    flex-wrap: wrap;
    margin-top: rem(30);
    margin-bottom: rem(30);
    align-items: center;

    @media(min-width: $screen-960) {
      max-width: rem(670);
      flex-wrap: nowrap;
    }
  }

  .icon {
    margin: auto;

    @media(min-width: $screen-960) {
      padding-right: rem(50);
      margin: initial;
    }

    svg {
      width: rem(100);
      height: rem(100);
      color: $color-mantis;
    }
  }

  .side-image {
    @media(max-width: $screen-960) {
      margin: rem(80) auto;
    }

    img {
      @media(max-width: $screen-960) {
        width: 100%;
      }
    }
  }

  h1 {
    font-size: rem(30);
    margin-top: rem(40);

    @media(min-width: $screen-960) {
      font-size: rem(35);
      margin-top: 0;
    }

  }

  .inner-content-wrapper {
    flex-direction: column;

    @media(min-width: $screen-960) {
      flex-direction: row;
      padding-left: 13%;
    }
  }

  .thank-you-content {
    max-width: rem(670);
  }

  .disclamer {
    max-width: rem(617);
    font-size: rem(28);
    line-height: rem(34);
  }

}

.main-container {
  .main-content {
    &.thank-you {
      @media(max-width: $screen-960) {
        margin-top: 0;
        padding-top: 0;
      }
      .content {
        &.payment-info {
          .member-info {
            .table-row {
              max-width: rem(500);

              .table-column {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
