.back-link {
  padding-bottom: 0;
  display: flex;
  align-items: center;
  margin-bottom: rem(20);

  @media(max-width: $screen-960) {
    padding-bottom: 0;
    margin-bottom: rem(20);
  }

  svg {
    margin-right: rem(20);
  }
}

.bank-account-label {
  font-size: rem(28);
}

.bank-account-type-label {
  font-size: rem(24);
  font-weight: 600;
}
