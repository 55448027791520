.invoice-modal {
  margin-bottom: 20px;

  .view-invoice {
    cursor: pointer;
    color: $color-bamboo-light;
    @include fluid-type(17px, 21px);
    font-weight: 500;
    font-family: $font-default;

    svg {
      margin-right: rem(20);
    }
  }
}

.ReactModal__Overlay {
  overflow-y: auto;
}

.ReactModalPortal {
  padding-bottom: 1.25rem;
  z-index: 2;
  position: absolute;

  .ReactModal__Content {
    @media (min-width: $screen-960) {
      width: 80vw;
    }

    object {
      height: 70vh;
    }
  }

  .modal__header {
    .modal__button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .modal-content {
    flex-direction: column-reverse;
  }

  .modal-footer {
    justify-content: flex-start;
  }

  .modal-body {
    embed {
      min-width: 100%;
      height: 800px;
      @media (max-width: $screen-960) {
        height: 400px;
      }
    }

  }

  .react-pdf__Document {
    overflow: auto;
    border: 1px solid lightgray;
  }

  .modal-footer {
    margin-top: 20px;
    position: initial;

    a {
      color: $color-bamboo-light;
      cursor: pointer;

      &.pdf-download {
        margin-right: 20px;
      }
    }
  }
}
