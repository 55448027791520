form {
  max-width: rem(570);

  @media(max-width: $screen-960) {
    max-width: 100%;
  }

  .elements-wrapper {
    position: relative;
  }

  .form-label {
    padding-bottom: rem(10);
    font-size: rem(24);

    &.phone-label {
      @media(max-width: $screen-768) {
        font-size: rem(17);
      }
    }

    &.dob-label {
      padding-top: rem(20);
      @media(max-width: $screen-768) {
        font-size: rem(17);
      }
    }
  }

  .routing-number-disclaimer {
    padding-bottom: rem(10);
    font-size: rem(21);

    .secure-info {
      svg {
        margin-left: rem(30);
      }
    }
  }

  .select__menu {
    z-index: 300;
  }

  .line {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    span {
      @media(max-width: $screen-767) {
        display: none;
      }
    }

    @media(max-width: $screen-767) {
      flex-wrap: wrap;
    }

    > div {
      flex-basis: 46%;

      @media(max-width: $screen-767) {
        flex-basis: 100%;
      }
    }
  }

  .select-field--wrapper,
  .text-field--wrapper {
    margin-bottom: rem(45);
    padding-top: 0;
    position: relative;

    &:focus-within {
      label {
        color: $color-boulder;
        font-size: 0.75em;
        font-weight: 700;
        top: rem(5);
        text-transform: uppercase;
      }
    }

    .select__value-container {
      padding: 0 rem(12);

      .select__placeholder {
        font-size: rem(24);
        color: $color-mine-shaft;
        margin: 0;

        @media(max-width: $screen-768 - 1) {
          font-size: rem(16);
        }
      }
      .select__input-container {
        margin: 0;
        padding: 0;
      }
    }

    input.element-error,
    input.element-error.js-filled {
      border: rem(2) solid $color-thunderbird;
      color: $color-thunderbird;
      box-shadow: none;

      ~ label {
        color: $color-thunderbird;
      }
    }

    label {
      @include transition(font-size 150ms ease-out, top 150ms ease-out);
      color: $color-mine-shaft;
      font-size: rem(24);
      font-style: normal;
      font-weight: 400;
      left: rem(12);
      line-height: rem(30);
      margin: 0;
      overflow: hidden;
      padding: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      text-overflow: ellipsis;
      top: rem(18);
      white-space: nowrap;
      will-change: top;
      z-index: 1;

      @media(max-width: $screen-768 - 1) {
        font-size: rem(16);
        line-height: rem(22);
        top: rem(16);
      }

    }

    input {
      font-size: rem(24);
      line-height: rem(30);
      height: rem(60px);
      padding-left: rem(13);
      padding-top: rem(20);
      width: 100%;
      max-width: rem(606);
      color: $color-mine-shaft;
      background-color: $color-alabaster;
      border: rem(1px) solid $color-hillary;

      @media(max-width: $screen-768) {
        height: rem(50px);
        font-size: rem(16);
        line-height: rem(21);
        max-width: 100%;
        padding-top: rem(18);
        min-width: 95%;
      }
      @media(max-width: $screen-960) {
        min-width: 100%;
      }

      @media(max-width: $screen-768) {
        min-width: 95%;
      }

      &.form-date {
        ~ label {

          @media(max-width: $screen-768 - 1) {
            top: rem(14);
          }
        }

      }

      &.js-filled,
      &:focus {

        ~ label {
          color: $color-boulder;
          font-size: 0.75em;
          font-weight: 700;
          top: rem(5);
          text-transform: uppercase;
        }
      }

    }

    input.form-text, input.form-email, select, textarea {
      transition: background-color 50000s, filter 50000s; // adding a transition to trigger event in Edge, Firefox, and Internet Explorer

      &:-webkit-autofill {
        animation-duration: 50000s;
        animation-name: onAutoFillStart;
      }

      &:not(:-webkit-autofill) {
        animation-duration: 50000s;
        animation-name: onAutoFillCancel;
      }
    }
  }

  .submit-field--wrapper {
    margin-top: rem(50);

    @media(max-width: $screen-768 - 1) {
      margin-top: rem(25);
    }
  }

  .secure-info {
    button {
      position: relative;
      top: rem(-3);
    }
  }

  .payment-code-expiration {
    .secure-info {
      button {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}

.hsa-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  margin-top: rem(30);

  @media(max-width: $screen-960) {
    flex-wrap: wrap;
  }

  .hsa-label {
    font-size: rem(24);
    margin-right: rem(40);
    font-weight: 400;
    letter-spacing: rem(-1);

    @media(max-width: $screen-960) {
      flex-basis: 100%;
    }
  }

  .text-radio--wrapper {
    margin-right: rem(40);

    input[type="radio"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: $color-white;
      /* Not removed via appearance */
      font: inherit;
      color: currentColor;
      width: rem(24);
      height: rem(24);
      border: rem(1) solid $color-hillary;
      border-radius: 50%;
      display: grid;
      place-content: center;
      float: left;
      margin: rem(5) rem(10) 0 0;

      &::before {
        content: '';
        width: .65em;
        height: .65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
      }

      &:checked {
        &::before {
          transform: scale(1);
          background-color: $color-orange;
        }
      }
    }

    .form-control {
      font-size: rem(24);
      border: 0;

      &:focus-within {
        color: var(--form-control-color);
      }
    }
  }
}

//noinspection CssInvalidPseudoSelector
input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

//noinspection CssInvalidPseudoSelector
input[type=date]:required:focus::-webkit-datetime-edit {
  color: $color-mine-shaft;
}

::-webkit-calendar-picker-indicator {
  position: relative;
  top: rem(-10);
  right: rem(10);

  @media(max-width: $screen-768 - 1) {
    top: rem(-5);
  }
}

.total-and-payment {
  form {
    max-width: rem(975);

    &.review-form {
      max-width: rem(468);
    }
    .amount-label {
      padding: rem(20) 0;
      font-weight: 600;
      color: $color-mine-shaft;
      font-size: rem(24);
      line-height: rem(29);
    }
    .options {
      .text-field--wrapper {
        input {
          max-width: rem(606);

          @media(max-width: $screen-960) {
            max-width: 100%;
          }
        }
      }

      .payment-code-expiration {
        justify-content: space-between;
        height: rem(65);

        @media(max-width: $screen-960) {
          flex-direction: column;
          align-items: flex-start;
          height: auto;
        }

        .d-inline-flex {
          @media(max-width: $screen-960) {
            height: rem(65);
            margin-bottom: rem(25);
          }

        }

        .text-field--wrapper {
          min-width: auto;
          input {
            max-width: rem(237);

            @media(max-width: $screen-960) {
              max-width: 100%;
            }
          }
        }

        .select-field--wrapper {
          width: rem(307);

          @media(max-width: $screen-960) {
            max-width: 100%;
            width: 100%;
            height: rem(65);
            margin-bottom: rem(25);
          }

          input {
            &:focus,
            &:active {
              box-shadow: none;
            }
          }

          .select__control {
            border-color: $color-hillary;
            background: $color-alabaster;

            .select__single-value {
              font-size: rem(24);

              @media(max-width: $screen-960) {
                font-size: rem(16);
              }
            }

            &.select__control--menu-is-open {
              .select__indicator {

                &::after {
                  content: '';
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 rem(10) rem(20) rem(10);
                  border-color: transparent transparent $color-bamboo-light transparent;
                }

                svg {
                  display: none;
                }
              }
            }
          }

          .select__indicator-separator {
            display: none;
          }

          .select__indicator {
            position: relative;
            &::after {
              content: '';
              width: 0;
              height: 0;
              border-style: solid;
              border-width: rem(20) rem(10) 0 rem(10);
              border-color: $color-bamboo-light transparent transparent transparent;
            }

            svg {
              display: none;
            }
          }
        }
      }
    }

    .input-description {
      padding: rem(10) 0;
    }
  }
}

.pay-form {
  .submit-field--wrapper {
    margin-top: 0;
    margin-bottom: rem(50);

    @media(max-width: $screen-768 - 1) {
      margin: rem(25) 0;
    }
  }

  .billing-section {
    .label {
      font-size: rem(24);
      padding: rem(30) 0;
    }

    .billing-state-zip {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      @media(max-width: $screen-960) {
        flex-wrap: wrap;
      }

      .select-field--wrapper {
        width: rem(216);

        @media(max-width: $screen-960) {
          width: 100%;
        }

        input {
          &:focus,
          &:active {
            box-shadow: none;
          }
        }

        .select__control {
          border-color: $color-hillary;
          background: $color-alabaster;

          &.select__control--menu-is-open {
            .select__indicator {

              &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 rem(10) rem(20) rem(10);
                border-color: transparent transparent $color-bamboo-light transparent;
              }

              svg {
                display: none;
              }
            }
          }
        }

        .select__indicator-separator {
          display: none;
        }

        .select__indicator {
          position: relative;
          &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: rem(20) rem(10) 0 rem(10);
            border-color: $color-bamboo-light transparent transparent transparent;
          }

          svg {
            display: none;
          }
        }
      }

      .text-field--wrapper {
        input {
          width: rem(216);

          @media(max-width: $screen-960) {
            width: 100%;
          }
        }
      }
    }
  }



  .text-field--wrapper {

    @media(max-width: $screen-960) {
      width: 100%;
    }

    @media(min-width: $screen-960) {
      min-width: rem(605);
    }

    input {
      @media(max-width: $screen-960) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .secure-disclamer {
    margin-top: rem(50);
  }

  .card-icons {
    margin-left: rem(50);
    flex: 1;
    padding-top: rem(10);

    @media(max-width: $screen-820) {
      margin:0 0 rem(40);
    }

    @media(max-width: $screen-960) {
      margin-left: 0;
    }

    img {
      display: inline-block;
      margin-right: rem(10);

      &.hidden {
        display: none !important;
      }
    }
  }

  .card-number {
    @media(max-width: $screen-820) {
      flex-direction: column-reverse;
    }


    .text-field--wrapper {
      flex: 1;

      @media(max-width: $screen-960) {
        flex-basis: 100%;
      }
    }
    @media(max-width: $screen-960) {
      flex-wrap: wrap;
    }
  }
}

.review-form {
  @media(max-width: $screen-960) {
    margin-bottom: rem(30);
  }

  .error {
    text-align: left;
  }

  .submit-field--wrapper {
    margin-top: rem(10);
  }
}

.bottom-error {
  display: flex;
  align-items: center;
  margin-top: rem(50);
  border: rem(1) solid $color-thunderbird;
  padding: rem(17) rem(27) rem(17) rem(17);
  font-size: rem(20);
  max-width: rem(560);
  word-break: break-word;

  @media(max-width: $screen-768) {
    max-width: 100%;
    margin-top: rem(25);
  }

  svg {
    fill: $color-thunderbird;
    font-size: rem(30);
    margin: 0 rem(25) 0 rem(10);
  }

  a {
    color: $color-bamboo-light;
    white-space: nowrap;
  }
}

.hidden {
  display: none!important;
}

.select__single-value {
  font-size: rem(24);

  @media(max-width: $screen-768 - 1) {
    font-size: rem(16);
  }
}
