footer.swa-footer {
  background: $color-pearl-bush;
  text-align: center;
  padding-top: 0;
  margin-top: 0;
  border-top: 0;
  padding-bottom: rem(36);

  .inner-footer {
    padding: 0;
  }

  .swa-footer-one {
    background: $color-kabul;
    padding: 1.25em 0 0.75em;
    margin: 0 -0.938em;

    .global-element {
      color: $color-white;
      display: inline-block;
      font-weight: 100;
      line-height: rem(36);

      a {
        color: $color-white;
      }
    }
    //h2
    .lead-in {
      @extend .global-element;
      color: $color-akaroa;
      display: block;
      font-size: $global-text-label-fsize;
      line-height: 1;
      margin: 0;
      padding: 0;
    }

    //Get Started
    .global-search-sectn {
      margin: 0;
      font-size: $global-element-fsize;
      vertical-align: top;

      .fa {
        &:before {
          margin-right: 0.625em;
        }
      }

      > span {
        margin-left: 0.938em;
      }

      address {
        display: inline-block;
        margin: 0;
      }

      a {
        font-family: Arial, sans-serif;
        font-size: $global-element-fsize;
        color: $color-white;

        &:hover {
          color: inherit;
          text-decoration: inherit;
        }
      }
    }

    // Mobile < 768px
    @media screen and (max-width: $screen-767) {
      .col-sm-6 {
        text-align: left;
        padding-left: 25px;
      }
      .lead-in {
        font-size: 1.75em;
      }
      .global-search-sectn {
        margin-bottom: 1em;
      }
    }

    // < 1200px
    @media screen and (max-width: $screen-1200) {
      .global-search-sectn {
        > span {
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .global-s-element {
        .fa {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    // > 767px
    @media screen and (min-width: $screen-768) {
      .col-sm-6 {
        &:first-child {
          text-align: left;
        }
      }
    }

    // Desktop > 1199
    @media screen and (min-width: $screen-1200) {
      .lead-in {
        display: inline-block;
      }
    }
  }

  .swa-footer-one {
    .global-search-sectn {
      a {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
    .global-s-element {
      a {
        &:hover,
        &:focus {
          outline: rem(1) solid;
          outline-offset: 0.25em;
          text-decoration: none;
        }
      }
    }
  }

  .swa-footer-two {
    padding-top: rem(15);

    .swa-footer-menu {
      padding: 0;
    }

    nav {
      &:before,
      &:after {
        content: ' ';
        display: table;
      }
      ul {
        justify-content: center;
        border-bottom: rem(1) solid $color-soft-amber;
        padding-bottom: rem(15);
        padding-left: 0;
        list-style: none;
        margin: 0;

        a {
          color: $color-gray;
          text-transform: none;
          margin: 0;
          display: block;
          font-size: 0.875em;
          padding: rem(10) rem(15);

          &:hover,
          &:focus,
          &:active {
            background: transparent;
            color: $color-olive;
            text-decoration: underline;
          }
        }
      }
    }

    p {
      padding-top: 0.5em;
      color: $color-dusty-gray;
      font-size: 0.83em;
      margin: rem(10) 0 0;
    }

    .copyright {
      padding-top: 1.25em;
      .inner-copyright {
        color: $color-dusty-gray;
        font-size: 0.75em;
      }
    }
  }
}
