.swa-main-nav-wrapper {
  background: $color-orange;
  box-shadow: 0 rem(8) rem(20) rgba(0, 0, 0, 0.25);

  @media (max-width: $screen-767) {
    background: $color-bamboo;
  }

  .swa-logo {
    //Mobile.
    @media (min-width: $screen-767) {
      margin: 0.6em 0 0.6em 3.3em;
      width: auto;

      a {
        width: 8em;
        padding: 0;
      }
    }

    a {
      display: block;
      padding: 0.6em 0;
      // Added for accessibility.
      &:focus {
        outline: rem(1) solid $color-white;
        outline-offset: rem(1);
      }
    }

    img {
      width: 100%;

      @media (max-width: $screen-767) {
        padding: 0;
        width: 60%;
      }
    }
  }

  .phone-login {
    @media (max-width: $screen-767) {
      flex-direction: row-reverse;
      margin-right: rem(-12);
    }
  }

  .login-wrapper,
  .phone-wrapper {
    @media (max-width: $screen-768) {
      font-size: 0;
      width: rem(50);
      height: rem(50);
      display: block;
      background: $color-gray-light;
      padding-left: rem(10);
      padding-top: rem(19);
      padding-right: rem(16);

      &:before {
        font-size: rem(20);
      }
    }

    svg {
      width: rem(15);
      position: relative;
      top: rem(-2);
    }

    a {
      padding-left: rem(5);
      font-size: rem(15);

      @media (max-width: $screen-768) {
        font-size: 0;
      }
    }
  }

  .login-wrapper {
    @media (max-width: $screen-768) {
      background: $color-kabul;
      font-size: rem(10);
      padding-top: rem(7);
      padding-left: rem(10);
      padding-right: rem(10);
    }

    a {
      color: $color-white;
      text-transform: uppercase;
      text-decoration: none;

      @media (max-width: $screen-768) {
        font-size: rem(10);
      }
    }
  }
}

.header {
  h2 {
    display: none;

    @media (min-width: $screen-960) {
      display: initial;
    }
  }
}

.mobile-header {
  flex: 1;
  font-size: rem(16);
  color: $color-white;
  text-transform: uppercase;
  margin-left: rem(18);

  @media (min-width: $screen-960) {
    display: none;
  }
}

// Unset pseudo icons on external links.
a[href^='http']:after {
  content: none !important;
}
