@use "sass:math";

@mixin center-align {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

/* MIXINS ARE DEFINED IN THIS FILE */

/**
 *  Takes any value and strips the unit off
 *
 * @param string
 *   Value
 *
 * @return number
 *   Number without unit.
 */
@function strip-unit($value) {
  @if type-of($value) == 'number' and not unitless($value) {
    @return math.div($value, $value * 0 + 1);
  }

  @return $value;
}

/**
 * Converts pixels to rem.
 *
 * @param int|float
 *   Pixels.
 *
 * @return string
 *   Rem value.
 */
@function rem($value) {
  @return math.div(strip-unit($value), strip-unit($body-base-fsize))+rem;
}

/* MIXIN FOR BORDER RADIUS STARTS HERE */
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}
/* MIXIN FOR BORDER RADIUS ENDS HERE */

@mixin content-img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

@mixin menu-border-mobile {
  position: absolute;
  left: 5%;
  width: 91%;
  content: '';
  border-bottom: rem(1) solid $color-burnt-orange;
  bottom: 0;
}
/* MIXIN FOR MAIN NAV UP-DOWN ARROW ENDS HERE */

/**/
@mixin phone-icon-mobile-header {
  width: 4.5em;
  color: transparent;
  padding: 0 1em;
  line-height: 4.5em;
  background: $color-phone-icon-bg;
  position: absolute;
  right: 0;
}

/* MIXIN FOR ICONS IN MOBILE HEADER */
@mixin icon-mobile-header {
  background-color: inherit;
  color: transparent;
  height: 4.5em;
  line-height: 4.5em;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 4.5em;
}
/**/

/* MIXIN FOR BUTTON STYLE STARTS HERE */

@mixin button-style {
  font-size: $btn-fsize;
  background: $color-bamboo;
  text-transform: uppercase;
  color: $color-white;
  padding: .25em .5em;
  text-decoration: none;
  margin: .5em 0;
  display: inline-block;
}

/**
 * Fluid Typography.
 * Example: @include fluid-type(16px, 40px);
 */
@mixin fluid-type($min-font-size, $max-font-size) {
  $min-vw: 320px;
  $max-vw: 1000px;

  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);


  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {

      font-size: rem($min-font-size);
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size) - strip-unit($min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw) - strip-unit($min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: rem($max-font-size);
      }
    }
  }
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
  box-shadow: $shadow;
}
