.main-container {
  .form-content {
    max-width: rem(1170);
    margin: 0 auto;
    padding-top: rem(40);

    @media (max-width: $screen-768) {
      padding-top: rem(20);
    }

    .slogan {
      color: $color-mine-shaft;
      font-family: $font-default;
      font-size: 0;
      font-weight: 400;

      @media (min-width: $screen-1200) {
        font-size: rem(28);
        max-width: rem(600);
        padding-top: rem(20);
      }
    }
  }

  .main-content {
    padding-top: 0;

    @media (max-width: $screen-960) {
      padding-top: rem(80);
    }

    .back-link {
      color: $color-bamboo-light;

      i {
        margin-right: rem(10);
      }
    }

    .log-in {
      color: $color-bamboo-light;
      @include fluid-type(17px, 21px);
      font-weight: 500;
      font-family: $font-default;
    }

    .content {
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-960) {
        justify-content: space-between;
        flex-direction: row;
      }

      .side-image {
        margin: rem(30) auto;

        @media (min-width: $screen-960) {
          margin: initial;
        }

        img {
          max-width: rem(278);

          @media (min-width: $screen-1200) {
            max-width: rem(556);
          }
        }
      }

      &.payment-info {
        flex-direction: column;
        @media (max-width: $screen-960) {
          padding-top: 0;
        }

        .table-info {
          font-style: normal;
          font-weight: 400;
          font-size: rem(28);
          line-height: rem(24);
          letter-spacing: -0.02em;
          color: $color-mine-shaft;
          margin: rem(50) 0 0;

          @media (max-width: $screen-960) {
            font-size: rem(24);
          }
        }

        .member-info {
          margin-top: rem(20);

          @media (max-width: $screen-960) {
            margin-top: 0;
          }

          .table-row {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            justify-content: space-between;
            max-width: rem(600);

            .table-column {
              width: rem(250);

              .info,
              .label {
                font-weight: 600;
                color: $color-mine-shaft;
                font-size: rem(24);
                line-height: rem(29);
                padding-top: rem(40);

                @media (max-width: $screen-960) {
                  font-size: rem(20);
                  padding-top: rem(20);
                }
              }

              .info {
                font-weight: 500;
                padding-top: rem(15);
              }
            }
          }
        }

        .disclamer {
          margin: rem(30) 0;
          font-size: rem(24);
          line-height: rem(29);
          color: $color-mine-shaft;

          @media (max-width: $screen-960) {
            margin: rem(15) 0;
            font-size: rem(20);
          }
        }

        hr {
          border-color: $color-mine-shaft;
          opacity: 1;
        }
      }
    }
  }

  .options {
    margin: rem(30) auto;
    width: 100%;

    @media (max-width: $screen-960 - 1) {
      margin: 0 auto;
    }

    @media (min-width: $screen-960) {
      margin: rem(-20) 0;
      padding-bottom: rem(50);
      padding-top: rem(20);
    }

    .react-tabs__tab-list {
      border-bottom: 0;
    }

    ul {
      list-style: none;
      display: flex;
      flex-wrap: nowrap;
      margin: 0 0 rem(35);
      padding: 0;

      li {
        border: rem(2) solid $color-bamboo-light;
        background-color: $color-bamboo-light;
        line-height: 1.8em;
        text-transform: uppercase;
        color: $color-white;
        min-height: rem(62);
        margin: 0 rem(1px);
        font-weight: normal;
        cursor: pointer;
        border-radius: 0;
        padding: rem(15) rem(45);
        font-size: rem(22);
        bottom: 0;

        &.react-tabs__tab--disabled {
          background-color: $color-orange;
          border-color: $color-alto;
          color: #1f1f1f;
          opacity: 0.5;
          cursor: not-allowed;
        }

        @media (max-width: $screen-960 - 1) {
          font-size: rem(13);
          min-height: 0;
          padding: rem(10) 0;
          width: 100%;
          text-align: center;
        }

        &:focus {
          &:after {
            display: none;
          }
        }

        &.react-tabs__tab {
          svg {
            display: none;
          }
        }

        &.react-tabs__tab--selected {
          background: $color-white;
          margin: 0 rem(-1px);
          color: $color-bamboo-light;
          position: relative;

          svg {
            display: block;
            color: $color-bamboo-light;
            left: rem(10);
            top: 50%;
            position: absolute;
            transform: translateY(-50%);

            @media (max-width: $screen-768 - 1) {
              padding-left: rem(10);
              left: rem(-5);
            }
          }
        }

        &:last-child {
          border-right: rem(3) solid $color-bamboo-light;
        }

        button {
          background: transparent;
          border: 0;
          font-weight: 500;
          @include fluid-type(16px, 22px);
          font-family: $font-default;
          color: $color-white;
          padding: rem(10) rem(24);

          @media (max-width: $screen-768 - 1) {
            padding: rem(7) rem(17);
          }
        }
      }
    }
  }
}

.pay-button-column {
  padding-top: rem(130);

  @media (max-width: $screen-768) {
    padding-top: 0;
  }
}

.page-verify-account {
  .main-content {
    .content {
      width: 100%;
      padding-top: 0;

      @media (max-width: $screen-960) {
        padding-top: rem(30);
      }
    }
  }
}
