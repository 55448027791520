:root {
  --form-control-color: $color-alabaster;
  --base-font-size: $font-size-base;
}

body,
h1, h2, h3, h4, h5, h6 {
  font-family: $font-default;
  font-size: $body-base-fsize;
  margin: 0;
}

h1 {
  font-weight: 400;
  @include fluid-type(30px, 32px);
  color: $color-mine-shaft;
  font-family: $font-default;
}

h2 {
  @include fluid-type(16px, 40px);
  color: $color-olive;
  font-weight: 400;
  font-family: $font-default;
  margin-bottom: rem(20);
}

h3 {
  color: $color-dove-gray;
  font-family: $font-default;
  font-size: 0;
  font-weight: 400;

  @media (min-width: $screen-1200) {
    font-size: rem(40);
  }
}

h4 {
  color: $color-mine-shaft;
  @include fluid-type(30px, 34px);
  font-family: $font-default;
  font-weight: 400;
  margin-top: rem(12);
}

h6 {
  color: $color-bamboo-light;
  @include fluid-type(17px, 21px);
  font-weight: 500;
  font-family: $font-default;
}

p {
  font-size: $pdetail-fsize;
  line-height: $pdetail-lh;
  margin-top: rem(10);
}

a,
a:focus {
  text-decoration: none;
  color: $color-bamboo;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: underline;
  color: $color-bamboo;
}

iframe {
  width: 100%;
}

/* STYLES FOR FORM ELEMENTS STARTS HERE */
.btn-primary,
.btn,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary[disabled]:hover,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  @include button-style;
}

//Added for accessibility
.btn:focus,
.button:focus {
  outline: rem(3) solid $color-tenn;
}

.container {
  &.header-container {
    @media (min-width: $screen-1200) {
      width: $screen-1300;
    }

    @media (max-width: $screen-1300) {
      width: 100%;
    }
  }
}

.btn-default {
  font-family: $font-default;
  height: rem(67);
  border: .2rem solid $color-bamboo;
  background-color: $color-bamboo;
  color: white;
  text-decoration: none;
  font-size: rem(24);
  font-weight: 300;
  text-align: center;
  letter-spacing: .05em;
  text-transform: uppercase;
  border-radius: 0;
  margin: 0;
  transition: .5s all ease;
  padding: rem(10) rem(25);
  width: 100%;

  @media (min-width: $screen-960) {
    width: auto;
  }

  @media(max-width: $screen-768 - 1) {
    height: rem(60);
    font-size: rem(16);
    padding: 0;
  }

  &:hover {
    color: $color-bamboo;
    background: $color-white;
  }
}

.pagination-navigation {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media(min-width: $screen-768) {
      position: static;
      transform: none;
      justify-content: center;
    }

    @media(min-width: $screen-960) {
      float: right;
      position: relative;
      top: rem(-60);
      left: initial;
      transition: none;
    }

    @media(min-width: $screen-820) and (max-width: $screen-1200) {
      left: rem(-60);
    }

    @media(min-width: $screen-1200) {
      top: rem(-160);
      left: initial;
      transform: none;
    }

    li {
      border-radius: 50%;
      background: $color-alto;
      position: relative;
      width: rem(20);
      height: rem(20);
      margin-right: rem(100);

      @media(min-width: $screen-960) {
        width: rem(40);
        height: rem(40);
        background: $color-alabaster;
      }

      @media(min-width: $screen-1200) {
        width: rem(80);
        height: rem(80);
        margin-right: rem(130);
      }

      &:after {
        content: '';
        height: rem(1);
        background: $color-mine-shaft;
        display: block;
        top: 50%;
        position: absolute;
        width: rem(100);
        left: rem(20);

        @media(min-width: $screen-960) {
          left: rem(40);
        }

        @media(min-width: $screen-1200) {
          width: rem(133);
          left: rem(80);
        }
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      &.active {
        background: $color-bamboo;

        a,
        span.active {
          font-weight: 600;
        }

        span {
          color: $color-alto;

          @media(min-width: $screen-960) {
            color: $color-alabaster;
          }

        }
      }

      span {
        font-size: 0;

        @media(min-width: $screen-960) {
          color: $color-mine-shaft;
          @include center-align;
          font-size: rem(17);
        }

        @media(min-width: $screen-1200) {
          font-size: rem(35);
        }
      }

      a,
      span.not-active,
      span.active,
      h2 {
        font-size: rem(14);
        font-weight: 600;
        color: $color-mine-shaft;
        @include center-align;
        top: rem(45);
        width: rem(100);
        word-break: break-word;
        white-space: break-spaces;
        text-align: center;

        @media(min-width: $screen-960) {
          top: rem(60);
          font-size: rem(16);
          width: 100%;
          word-break: unset;
          white-space: nowrap;
          text-align: left;
        }

        @media(min-width: $screen-1200) {
          top: rem(123);
          font-size: rem(20);
          left: 10%;
        }
      }

      a,
      span.not-active {
        font-weight: 400;
        border: 0;
        background: transparent;
      }
    }
  }
}

.mobile-only {
  @media(min-width: $screen-768) {
    display: none!important;
  }
}

.desktop-only {
  @media(max-width: $screen-768) {
    display: none !important;
  }
}

.desktop-tablet {
  display: none !important;

  @media(min-width: $screen-960) {
    display: initial !important;;
  }
}


.mobile-tablet {
  display: initial !important;;

  @media(min-width: $screen-960) {
    display: none!important;
  }
}

svg[class^='MuiSvgIcon'] {
  display: inline;
}

.color-brand {
  color: $color-orange;
}

.error {
  color: $color-thunderbird;
  font-size: rem(21);
  padding: rem(10) 0 rem(30);

  &:empty {
    display: none;
  }
}

.inline-error {
  color: $color-thunderbird;
  font-size: rem(21);
  z-index: 99;
  white-space: nowrap;

  @media(max-width: $screen-768) {
    font-size: rem(14);
    top: rem(-20);
  }

  &:empty {
    display: none;
  }
}

div.required:after,
label.required:after,
div.select-state-required .select__placeholder:after,
div.select-exp-required .select__placeholder:after {
  content:" *";
  color: red;
}

.spinner {
  max-width: 500px;
}

.spinner-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

// if submit button is disabled, make it look disabled
input[type='submit']:disabled {
  background-color: $color-orange;
  border-color: $color-alto;
  color: $color-mine-shaft;
  opacity: .5;
  cursor: not-allowed;
}

/* Remove arrows on the number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input.js-conceal { 
  -moz-text-security: disc;
  -webkit-text-security: disc; 
}
